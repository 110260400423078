<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/roleManagement' }">角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always">
      <div class="title">信息筛选</div>
      <div class="search-content">
        <div class="search-box">
          <div class="search">
            <div class="search-box__title">角色名称</div>
            <el-input v-model.trim="roleName" placeholder="请输入角色名称" />
          </div>
          <div class="search" style="margin-left: 40px">
            <div class="search-box__title">所属部门</div>
            <el-input v-model.trim="departmentName" placeholder="请输入所属部门" />
          </div>
        </div>
        <div class="search-btn" @click="search">筛选</div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">角色列表</div>
        <div class="header__btn" @click="addRole">新增角色</div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333',}" :cell-style="{ 'text-align': 'center', color: '#333' }" style="width: 100%">
          <el-table-column prop="roleId" label="角色编号" />
          <el-table-column prop="roleName" label="角色名称" />
          <el-table-column prop="deptName" label="所属部门" />
          <el-table-column prop="createTime" label="创建时间" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="text" @click="showDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRouter } from 'vue-router'
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      roleName: "",
      departmentName: "",
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0
    });

    onMounted(() => {
      getList();
    })

    const search = () => {
      getList();
    };

    const getList = () => {
      let data = {
        deptName: state.departmentName,
        endTime: "",
        roleName: state.roleName,
        startTime: "",
        pageNum: state.currentPage,
        pageSize: state.pageSize,
      }
      proxy.$post('/cts/back/role/list', data).then((res) => {
        if (res.code === '200') {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      }).catch((err) => {

      });
    }

    const showDetail = (data) => {
      router.push({ path: '/roleManagement/detail', query: {id: data.roleId} })
    };
    const addRole = () => {
      router.push({ path: '/roleManagement/add' })
    };

    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    };

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    }

    return {
      ...toRefs(state),
      search,
      showDetail,
      addRole,
      handleSizeChange,
      handleCurrentChange
    };
  },
};
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-box__title {
        width: 130px;
      }
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__title {
      font-weight: bold;
    }
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
.role_card {
  .role_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 30px;
  }
}
</style>
